import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import DefaultLayout from './Components/Common/DefaultLayout/DefaultLayout';
// import { routes } from './routes';
// import Home from './Components/Home/Home';
// import About from './Components/About/About';
// import Careers from './Components/Careers/Careers';
import Aux from './hoc/Aux';
import NotFound from './Components/NotFound/NotFound';

// const NotFound = lazy(() => import("./Components/NotFound/NotFound"));

class App extends Component {
  render() {
    return (
      <Aux>
        {/* <DefaultLayout>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/careers" exact component={Careers} />
            {routes.map((route, idx) => {
              return route.component ? (<Route key={idx} path={route.path} exact={route.exact} render={props => (
                <route.component {...props} />
              )} />) : null
            })}
          </Switch>
        </DefaultLayout> */}
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* <Route path="/404" exact={true} component={NotFound} /> */}
            <Route exact path="/404" name="NotFound" render={props => <NotFound {...props} />} />
            <Route path="/" name="home" render={props => <DefaultLayout {...props} />} />
            {/* <Redirect from="*" to="/404" /> */}
            
          </Switch>
        
        </Suspense>

      </Aux>
    );
  }
}

export default App;
