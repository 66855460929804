import React, { Component } from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom';
import Aux from '../../../hoc/Aux';

export class Menu extends Component {
    render() {
        return (
            <Aux>
            <>
                <nav className="navbar navbar-default navbar-fixed-top">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <Link to="/"> <img className="navbar-brand cus-nav-logo" src="/images/logo.svg" alt="no img" /></Link>
                        </div>
                        <div className="collapse navbar-collapse" id="myNavbar">
                            <ul className="nav navbar-nav navbar-right">
                                <li className="dropdown">
                                    <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Products <span className="caret"></span></a>
                                    <ul className="dropdown-menu cus-drop-menu" role="menu">
                                        <div className="prod-men-col1">
                                            <h5>Products</h5>
                                            <p>Product suite to manage the entire flow of identity for your business</p>
                                        </div>
                                        <div className="prod-men-col2">
                                            <Link to="/edo-suite">
                                                <li>Edo Suite  <blink className="new-blink">NEW</blink><br />
                                                    <span className="un-bold">Mask first 8 digits of Aadhaar <br /> Number with Syntizen’s Edo technology</span>
                                                </li>
                                            </Link>
                                            <Link to="/zen-aua">
                                                <li>ZEN AUA/KUA Platform <br />
                                                    <span className="un-bold">A comprehensive AUA / KUA suite which is compliant with all UIDAI regulations.</span>
                                                </li>
                                            </Link>
                                            <Link to="/zen-asa">
                                                <li>ZEN ASA Platform <br />
                                                    <span className="un-bold">Empower Organizations with ZEN ASA Platforms to avail UIDAI Services</span>
                                                </li>
                                            </Link>
                                            <Link to="/sms">
                                                <li>Subsidy Management System <br />
                                                    <span className="un-bold">Right system in place to identify the right beneficiary.</span>
                                                </li>
                                            </Link>
                                            <Link to="/synzap">
                                                <li>SYNZAP <br />
                                                    <span className="un-bold">Tamper-proof, Authentic & Easy to Integrate Attendance Solution.</span>
                                                </li>
                                            </Link>
                                            <Link to="/ekyc">
                                                <li>Offline e-KYC <br />
                                                    <span className="un-bold">Identify a customer instantly using his/her Aadhaar Card in Multiple Ways.</span>
                                                </li>
                                            </Link>

                                        </div>
                                        <div className="prod-men-col3">
                                            <Link to="/video-kyc">
                                                <li>Video KYC  <blink className="new-blink">NEW</blink><br />
                                                    <span className="un-bold">Why go for conventional paper-based identification which is tiresome, and less secure when identification can be done digitally? Yes, and that too, while
                                                    ensuring higher speed, safety, and security.
                                                    </span>
                                                </li>
                                            </Link>
                                            <Link to="/e-sign">
                                                <li>e-Sign <br />
                                                    <span className="un-bold">Enabling Organizations to Digitally Sign Documents</span>
                                                </li>
                                            </Link>
                                            <Link to="/digital-affiliation">
                                                <li>Digital Affiliation<br />
                                                    <span className="un-bold">An easy, quick and foolproof solution for any Government University/Board.</span>
                                                </li>
                                            </Link>
                                            <Link to="/enach">
                                                <li>e-Nach <br />
                                                    <span className="un-bold">Stepping towards a new digital payment mechanism</span>
                                                </li>
                                            </Link>
                                            <Link to="/getkyc">
                                                <li>GetKYC <br />
                                                    <span className="un-bold">India's first customer on-boarding gateway</span>
                                                </li>
                                            </Link>
                                            <h6>Enterprise Suite</h6>
                                            <Link to="/ecos">
                                                <li>e-COS <br />
                                                    <span className="un-bold">Ease your business process beginning from customer onboarding till Automated Payments.</span>
                                                </li>
                                            </Link>
                                            <h6>Tools(Paas - Platform as a Service</h6>
                                            <Link to="/lotsms">
                                                <li>Lot SMS <br />
                                                    <span className="un-bold">Right tool for an Organization to reach more people</span>
                                                </li>
                                            </Link>
                                            <Link to="/panverification">
                                                <li>PAN Verification <br />
                                                    <span className="un-bold">A facility provided to certain eligible entities for verification of PAN details of a Citizen.</span>
                                                </li>
                                            </Link>
                                        </div>
                                    </ul>
                                </li>
                                {/* <li><a href="javascript:void(0)" >Solutions</a></li> */}
                                <li><Link to="/about"> About</Link></li>


                                <li className="dropdown">
                                    <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Company <span className="caret"></span></a>
                                    <ul className="dropdown-menu cus-drop-menu m-w" role="menu">
                                        <div className="prod-men-col1 m-w1">
                                            <h5>Company</h5>
                                            <p>Global Identification Solutions.</p>
                                        </div>
                                        <div className="prod-men-col2 m-w2">

                                            <Link to="/leadership">

                                                <li>Leadership</li>
                                            </Link>

                                            <Link to="/partners">
                                                <li>Partners</li>
                                            </Link>
                                            <Link to="/clients">
                                                <li>Clients</li>
                                            </Link>

                                            <Link to={{ pathname: '/contact', state: { prevPath: window.location.pathname } }}>
                                                <li>Contact</li>
                                            </Link>
                                            {/* <Link to="/investers">
                                            <li>Investers</li>
                                            </Link> */}
                                            {/* <Link to="/board-of-directors">
                                            <li>Board Of Directors</li>
                                            </Link> */}


                                        </div>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Newsroom <span className="caret"></span></a>
                                    <ul className="dropdown-menu cus-drop-menu m-w" role="menu">
                                        <div className="prod-men-col1 m-w1">
                                            <h5>Newsroom</h5>
                                            <p>Global Identification Solutions.</p>
                                        </div>
                                        <div className="prod-men-col2 m-w2">

                                            <Link to="/media">
                                                <li>Media</li>
                                            </Link>

                                            <Link to="/pressrelease">
                                                <li>Press Releases</li>
                                            </Link>

                                            <a href="javascript:void(0)">
                                                <li>Blog</li>
                                            </a>

                                            <Link to="/newsletters">
                                                <li>Newsletter</li>
                                            </Link>
                                            <Link to="/videos">
                                                <li>Video Gallery</li>
                                            </Link>
                                            {/* <Link to="/investers">
                                            <li>Investers</li>
                                            </Link> */}
                                            {/* <Link to="/board-of-directors">
                                            <li>Board Of Directors</li>
                                            </Link> */}


                                        </div>
                                    </ul>
                                </li>
                                {/* <li><Link to={{pathname: '/contact', state: { prevPath: window.location.pathname }}}> Log In</Link></li> */}
                                <li><Link to={{ pathname: '/get-started', state: { prevPath: window.location.pathname } }} className="cus-nav-m">  <button className="btn-back-dark-small ">Get Started</button></Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>

            </Aux>
        )
    }
}

export default Menu;
